(function () {
	var $btn	= $('#menu__toggler');
	var $menu 	= $('#menu__oculto');
	var $fechar	= $('#fecha__menu')

	$btn.click(function(e) {
		e.preventDefault();

		$menu.addClass('menu__oculto--shown');

		$btn
			.attr('aria-expanded', 'true')
			.addClass('menu__toggler--active');
	})
	$fechar.click(function(g) {

		$btn
			.attr('aria-expanded', 'false')
			.removeClass('menu__toggler--active');

		$menu
			.addClass('menu__oculto--hide');

		setTimeout(function() {
			$menu.removeClass('menu__oculto--hide menu__oculto--shown');
		}, 600);
	})
}());;
