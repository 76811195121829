(function() {
	var carousel = $('.owl__bs__sync');

	carousel.each(function() {
		var $this 	= $(this);
		var target 	= $this.data('sync');
		var owl 	= $(target);

		$this.on('slide.bs.carousel', function(evento) {
			var to = evento.to;

			owl.trigger('to.owl.carousel', [to, 600, true]);

			owl.find('[data-slide-to]').removeClass('galeria__item--active');
			owl.find('[data-slide-to="'+to+'"]').addClass('galeria__item--active');
		});
	});

})();